import React from 'react'
import { IoRemoveOutline } from "react-icons/io5";
import {Row,Col} from 'react-bootstrap';
import {useSpring, animated} from 'react-spring';


function Number ({n}) {
    const { number } = useSpring({
      from: { number: 0 },
      number: n,
      delay: 6000,
      config: { mass: 1, tension: 20, friction: 10 },
  })
  return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>;
}  

const CounterMainHome = () => {

  return (
    <div className='container'>
        {/* <span style={{color:'#FC4243',paddingLeft: 'inherit'}}>WORK PROCESS<IoRemoveOutline size={30} style={{marginBottom:'5px'}} /></span> */}
          <h2 style={{paddingLeft: 'inherit', textTransform:'uppercase'}}>Delivering Excellence Across the UK</h2>
          <Row className='mt-4 container-fluid'>
            <Col xs={12} md={4}>
              <h1 style={{fontWeight: 'bolder', display:'flex'}}><Number n={3000}/> +</h1>
              <h4 style={{fontFamily:'Lato, sans-serif'}}>Satisfied Customers</h4>
              <p style={{fontFamily:'Lato, sans-serif'}}>Thousands have enjoyed smooth <br/>relocations across the UK,<br/>thanks to our outstanding service.</p>
              <div className='textmoniLine' style={{height: '100px',borderLeft: '1px solid #666', position:'relative',top:'-130px',right:'15px', float:'right'}}></div>
            </Col>

            <Col xs={12} md={4}>
              <h1 style={{fontWeight: 'bolder',color:'#0827b7', display:'flex'}}><Number n={50}/> +</h1>
              <h4 style={{fontFamily:'Lato, sans-serif'}}>Deliveries</h4>
              <p style={{fontFamily:'Lato, sans-serif'}}>With a wide-reaching network,<br/> pickup, and delivery across Canada<br/> we ensure quick pickups and deliveries <br/>across the UK.</p>
              <div className='textmoniLine' style={{height: '100px',borderLeft: '1px solid #666', position:'relative',top:'-130px',right:'15px', float:'right'}}></div>
            </Col>
            <Col xs={12} md={4}>
              <h1 style={{fontWeight: 'bolder', display:'flex'}}><Number n={16}/>M+</h1>
              <h4 style={{fontFamily:'Lato, sans-serif'}}>KM per month</h4>
              <p style={{fontFamily:'Lato, sans-serif'}}>Our drivers cover millions of kilometers each month to ensure fast, reliable delivery across the UK, providing exceptional moving services nationwide</p>
            </Col>
          </Row>
    </div>
  )
}

export default CounterMainHome
