import React, { useEffect } from 'react';
import acvlbanner from '../images/TestMoli.jpg';
import TestHumen from '../images/Testmol.png';
import { FaCheck } from "react-icons/fa";
import { Row, Col, Card } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import Trip from '../images/Trip.jpg'
import Avatar from '@mui/material/Avatar';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

const Testimonial = () => {


  return (
    <div>
      <div style={{ backgroundImage: `url(${acvlbanner})`, backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}}>
      {/* <div style={{ backgroundImage: `url(${acvlbanner})`, backgroundRepeat: 'no-repeat' }}> */}
        <div className='container' >
          <Row>
            <Col className='TestmoniCol'>
              <img src={TestHumen} alt='km' style={{ width: '533px' }} />
            </Col>
            <Col >
              <div style={{ marginTop: '50px' }}>
                <h6 style={{ color: '#0827b7',fontFamily:'Lato, sans-serif',fontSize: 'larger' }}>Clients Tales</h6>
                <h3 style={{ fontWeight: '700', fontFamily:'Lato, sans-serif',fontSize: '31px' }}>Read Stories from Our happy Clients</h3>
              </div>
              <Row className='mt-4'>
                <Col xs={12} md={6}>
                  <h6 style={{fontFamily:'Lato, sans-serif'}}><FaCheck className='me-2' />Moving made quick and easy</h6>
                  <h6 style={{fontFamily:'Lato, sans-serif'}}><FaCheck className='me-2' />Cost-effective moving solutions</h6>
                  <h6 style={{fontFamily:'Lato, sans-serif'}}><FaCheck className='me-2' />A safe and secure relocation experience</h6>
                </Col>
                <Col xs={12} md={6}>
                  <h6 style={{fontFamily:'Lato, sans-serif'}}><FaCheck className='me-2'  />Timely delivery, every time</h6>
                  <h6 style={{fontFamily:'Lato, sans-serif'}}><FaCheck className='me-2' />Efficient and careful handling of your belongings</h6>
                  <h6 style={{fontFamily:'Lato, sans-serif'}}><FaCheck className='me-2' />Transporting Goods</h6>
                </Col>
              </Row>
              
              <div className='mt-5 '>
              <Carousel className='carouselClass'>
                <Carousel.Item interval={2000}>
                <Card style={{ width: '100%' }}>
                  <Card.Body>
                    <Card.Subtitle className="my-2">
                      <Row>
                        <Col xs={3} md={2}  style={{ textAlign: '-webkit-center'}}>
                          <Avatar src='https://img.freepik.com/free-photo/front-view-smiley-man-holding-book_23-2149915900.jpg?t=st=1708755547~exp=1708759147~hmac=9a1e15c33e46f9540f8a86594279e9eb2bca62d07c3277e465f682e0667f4d82&w=360' alt="Remy Sharp"   />
                        </Col>
                        <Col xs={7} md={8} style={{padding:'0px'}}>
                          <h5 className='mb-0' style={{fontFamily:'Lato, sans-serif'}}>Vikram</h5>
                          <p style={{marginBottom:'0px',fontWeight: 'lighter'}}>in Leeds</p>
                        </Col>
                        <Col xs={2} md={2} className='quoteMobile'>
                        <FormatQuoteIcon style={{fontSize:'50px', color:'#0072BC'}}/>
                        </Col>
                      </Row>
                    </Card.Subtitle>
                    <Card.Text className='text-center mt-3 mb-3' style={{fontFamily:'Lato, sans-serif'}}>
                    The moving crew arrived punctually at my house, handled my belongings with great care, and completed the loading within the estimated time as quoted. Due to a delay in moving into my apartment, my items had to be stored in the truck for three days.
                    </Card.Text>
                  </Card.Body>
                </Card>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                <Card style={{ width: '100%' }}>
                  <Card.Body>
                    <Card.Subtitle className="my-2">
                      <Row>
                        <Col xs={3} md={2}  style={{ textAlign: '-webkit-center'}}>
                          <Avatar src="https://cdn.homestars.com/uploaded_images/0367/7899/unnamed_thumb.png" alt="Remy Sharp"  />
                        </Col>
                        <Col xs={7} md={8} style={{padding:'0px'}}>
                          <h5 className='mb-0' style={{fontFamily:'Lato, sans-serif'}}>Amit</h5>
                          <p style={{marginBottom:'0px',fontWeight: 'lighter'}}>in London</p>
                        </Col>
                        <Col xs={2} md={2} className='quoteMobile'>
                        <FormatQuoteIcon style={{fontSize:'50px', color:'#0072BC'}}/>
                        </Col>
                      </Row>
                    </Card.Subtitle>
                    <Card.Text className='text-center mt-3 mb-3' style={{fontFamily:'Lato, sans-serif'}}>
                    I contacted them at the last minute, and they arrived as promised, right on time. They provided excellent, professional packing services and delivered everything to my new home at a competitive price.
                    </Card.Text>
                  </Card.Body>
                </Card>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                <Card style={{ width: '100%' }}>
                  <Card.Body>
                    <Card.Subtitle className="my-2">
                      <Row>
                        <Col xs={3} md={2}  style={{ textAlign: '-webkit-center'}}>
                          <Avatar src="https://img.freepik.com/free-photo/portrait-smiling-man-outdoors-with-beanie_23-2149256267.jpg?t=st=1708755616~exp=1708759216~hmac=6d2f76bb80e50de8e4cfb8a80ef56eb1450cbe4c63527ea956dbd8d6cc89a429&w=360" alt="Remy Sharp"  />
                        </Col>
                        <Col xs={7} md={8} style={{padding:'0px'}}>
                          <h5 className='mb-0' style={{fontFamily:'Lato, sans-serif'}}>Ravi</h5>
                          <p style={{marginBottom:'0px',fontWeight: 'lighter'}}>in Manchester</p>
                        </Col>
                        <Col xs={2} md={2} className='quoteMobile'>
                        <FormatQuoteIcon style={{fontSize:'50px', color:'#0072BC'}}/>
                        </Col>
                      </Row>
                    </Card.Subtitle>
                    <Card.Text className='text-center mt-3 mb-3' style={{fontFamily:'Lato, sans-serif'}}>
                    Arjun and his team are hands down the best movers I've ever worked with. Over the past few years, I've hired them for five different short-haul moves within downtown and Oxford. They are careful with my belongings, considerate of property, always punctual, great listeners, and excellent communicators. I highly recommend them!
                    </Card.Text>
                  </Card.Body>
                </Card>
                </Carousel.Item>
              </Carousel>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;


