import React,{useEffect, useState} from 'react'
import Header from '../components/Header';
import {Fade,Zoom} from 'react-reveal';
import {Row,Col,Card,Button} from 'react-bootstrap';
import ContactBannerImg from '../images/Long-Distance-Mover.webp'
import serivePlan from '../images/serivePlan.jpg'
import serivePlan2 from '../images/serivePlan2.jpg'
import serivePlan3 from '../images/serivePlan3.jpg'
import serivePlan4 from '../images/serivePlan4.jpg'
import serivePlan8 from '../images/serivePlan8.jpg'
import serivePlan6 from '../images/serivePlan6.jpg'
import { AiFillSafetyCertificate } from "react-icons/ai";
import { IoWalletSharp } from "react-icons/io5";
import { FaTruckFast } from "react-icons/fa6";
import Accordion from 'react-bootstrap/Accordion';
import welcometruck from '../images/welcome-truck.png'
import Carousel from 'react-bootstrap/Carousel';
import Avatar from '@mui/material/Avatar';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import Footer from '../components/FooterMain'
import MoreService from '../components/MoreService'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import {Helmet} from 'react-helmet'
import Select from 'react-select'
import TrackImage from '../images/TrackImage.png'
import acvlbanner from '../images/acvl-banner2.png'
import {  toast } from 'react-toastify';
import { Link,useNavigate } from "react-router-dom";
import { MdCall } from "react-icons/md";

const LongDistanceMovers = () => {
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const utmCampaign = searchParams.get('utm_campaign');
    const utmId = searchParams.get('utm_id');

    if (utmCampaign) {
      sessionStorage.setItem('utm_campaign', utmCampaign);
    }

    if (utmId) {
      sessionStorage.setItem('utm_id', utmId);
    }
  }, []);
  useEffect(() => {
    // Function to get the IP address
    const fetchIpAddress = async () => {
      try {
        // Fetch the IP address from IPify API
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        const ipAddress = data.ip;

        // Send the IP address to your API
        await sendIpAddressToApi(ipAddress);

      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    // Function to send the IP address to your API
    const sendIpAddressToApi = async (ipAddress) => {
      try {
        const response = await fetch('https://allcanadavanlines.com/acvlbackend/api/ipaddress', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ipaddress: ipAddress }),
        });

        if (response.ok) {
          console.log('IP address sent successfully');
        } else {
          console.error('Failed to send IP address');
        }
      } catch (error) {
        console.error('Error sending IP address:', error);
      }
    };

    // Call the function to fetch IP and send it to your API
    fetchIpAddress();

  }, []); // Empty dependency array ensures this runs only once when the component mounts


  
  const [predictions, setPredictions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [predictionsto, setPredictionsto] = useState([]);
  const [inputValueto, setInputValueto] = useState("");
  const [predictionsInput, setpredictionsInput] = useState("");
  const [predictionsInputto, setpredictionsInputto] = useState("");

  
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const utmCampaign = searchParams.get('utm_campaign');
    const utmId = searchParams.get('utm_id');

    if (utmCampaign) {
      sessionStorage.setItem('utm_campaign', utmCampaign);
    }

    if (utmId) {
      sessionStorage.setItem('utm_id', utmId);
    }
  }, []);
  

  const navigate = useNavigate();

  //////////// FROM City API ///////////////
  const fetchDictionFrom = async (inputText) => {
    // if (inputText.length > 0) {
      try {
        const apiUrl = `https://allukmovers.com/ukmoversbackend/api/google-places-autocomplete?inputText=${inputText}`;

        const response = await fetch(apiUrl);
        const data = await response.json();
        console.log(data);
        if (data.predictions) {
          const options = data.predictions.map((prediction) => ({
            value: prediction.description,
            label: prediction.description,
          }));
          setPredictions(options);
        }
      } catch (error) {
        console.error("Error fetching predictions:", error);
      }
    // }
  };

  const handleSelectChange = (selectedOption) => {
    if (selectedOption && selectedOption.label) {
      setpredictionsInput(selectedOption);
    } else {
      setpredictionsInput(null); // or setpredictionsInput(defaultValue);
    }
  };

  useEffect(() => {
    fetchDictionFrom(inputValue);
  }, [inputValue]);

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };

  ///////////// TO City API ///////////////
  const fetchDictionTo = async (inputText) => {
    // if (inputText.length > 1) {
      try {
        const apiUrl = `https://allukmovers.com/ukmoversbackend/api/google-places-autocomplete?inputText=${inputText}`;

        const response = await fetch(apiUrl);
        const data = await response.json();
        console.log(data);
        if (data.predictions) {
          console.log("predictions", data.predictions);
          const optionsto = data.predictions.map((prediction) => ({
            value: prediction.description,
            label: prediction.description,
          }));
          setPredictionsto(optionsto);
        }
      } catch (error) {
        console.error("Error fetching predictions:" , error);
      }
    // }
  };

  const handleSelectChangeto = (selectedOption) => {
    if (selectedOption && selectedOption.label) {
      setpredictionsInputto(selectedOption);
    } else {
      setpredictionsInputto(null); 
    }
  };

  useEffect(() => {
    fetchDictionTo(inputValueto);
  }, [inputValueto]);

  const handleInputChangeto = (newValue) => {
    setInputValueto(newValue);
  };

////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleNextButtonClick = (event) => {
    event.preventDefault();     
    if (predictionsInput && predictionsInput.label && predictionsInputto && predictionsInputto.label) {
      const dataToSend = {
        from: predictionsInput.label,
        to: predictionsInputto.label,
      };

      console.log('NEXT button clicked. Navigating with data:', dataToSend);

      // Use navigate to navigate and pass data through state
      navigate('/CalenderForm', { state: { data: dataToSend } });

      

    } else {  
      toast.warn('locations are required', {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        })
    }
  }


  return (
    <div>
        <Helmet>
        <title>All Canada Van Lines| long distance moving company| Get a free quote | long distance movers</title>
        <meta name="description" content="With our dedicated team and nationwide network, we ensure a smooth transition to your new destination"/>
        </Helmet>
    <Header/>
    {/* Bannar */}
    <div className='Banner' style={{backgroundImage: `url(${acvlbanner})`,backgroundSize:'cover',backgroundRepeat: 'no-repeat',width:"100%", height:'350px',backgroundPosition: '105% 0%' }}>
        <div className='container-fluid'>
          <Row>
            <Col xs={7} className='Banner-title' style={{margin:'50px 0px 0px 0px'}}>
          <div  className='bannerHeading' style={{marginLeft: '50px', marginTop:'50px'}}>
              <Fade left>
              <h1 className='bannerHeading' style={{color:'#ffff', fontFamily:'sans-serif',fontWeight: "bold"}}>
               <main class="d-flex">
                <p className='prr mb-0'>Make Moving</p>
                <section class="animation">
                  <div class="first" style={{color:'#FEFFFA'}}><div>Easy</div></div>
                  <div class="second" style={{color:'#FEFFFA'}}><div>Safe And Sound</div></div>
                  <div class="third" style={{color:'#FEFFFA'}}><div>Affordable</div></div>
                </section>
              </main>
               </h1>
              {/* <h1 style={{color:'#ffff', fontFamily:'sans-serif',fontWeight: "bold"}}>Seamless moves across Canada!</h1> */}
              <h5 style={{color:'#ffff', fontFamily:'Lato, sans-serif'}}>Explore Our Exclusive Relocation Offers</h5>
              </Fade>
              {/* <div className='mt-4'>
                <Link to='/Aboutus' ><Button className='button-21'>Read More<RiArrowRightSLine size={19} className='mb-0' /></Button></Link>
              </div> */}
            </div>
            <Fade bottom>
            <div id='Process' className="display-Form" style={{border:'1px solid #262E3E', borderRadius:'10px', marginTop:'25px',backgroundColor:'#262E3E', width:'100%',marginLeft:'50px'}}>
              <div className='container my-4'>
                <Row>
                  <Col xs={5}>
                    <h6 style={{color:'#ffff', fontFamily:'Lato, sans-serif',fontWeight: '600'}}>I am moving from *</h6>
                    <Select 
                        isClearable
                        value={predictionsInput}
                        options={predictions}
                        onInputChange={handleInputChange}
                        onChange={handleSelectChange}
                        noOptionsMessage={() => 'Search Your City'}
                        isRequired placeholder='City' />
                  </Col>
                  <Col xs={5}>
                    <h6 style={{color:'#ffff', fontFamily:'Lato, sans-serif',fontWeight: '600'}}>I am moving to *</h6>
                    <Select 
                     isClearable
                     value={predictionsInputto}
                     options={predictionsto}
                     onInputChange={handleInputChangeto}
                     onChange={handleSelectChangeto}
                     noOptionsMessage={() => 'Search Your City'}
                     isRequired placeholder='City' />
                  </Col>
                  <Col xs={2} style={{marginTop:'20px'}}>
                     {/* <a href='/CalenderForm' ><Button style={{backgroundColor:'#202528', borderColor:'#202528'}}>NEXT</Button></a> */}
                     <Button className='button-70' onClick={handleNextButtonClick} style={{backgroundColor:'#202528', borderColor:'#202528',fontWeight: '600'}}>GET QUOTE</Button>
                  </Col>
                </Row>
              </div>
            </div></Fade>
            </Col>
            <Col xs={5}>
            <Fade right>
              <img className='TrackImge' src={TrackImage} alt='BannerImage' style={{ width:'430px',position: 'absolute',right: '0',marginTop: '3px'}} /></Fade>
            </Col>
          </Row>
        </div>
      </div>
  {/* -------------------------- START BannerReponse --------------------------- */}
  <div id='Process' className='Banner BannerReponse' style={{backgroundColor:'#FC4243',backgroundSize:'cover',backgroundRepeat: 'no-repeat',height:'348px',backgroundPosition: '105% 0%' }}>
    <div className='container-fluid'>
      <Row>
        <Col xs={12} className='Banner-title' style={{margin:'100px 0px 0px 0px'}}>
      <div className='bannerHeading' style={{marginLeft: '50px'}}>
          <Fade left>
          <h1 className='bannerHeading' style={{color:'#ffff', fontFamily:'Lato, sans-serif',fontWeight: "bold"}}>
          <main class="d-flex">
            <p className='prr'>Make Moving</p>
            <section class="animation">
              <div class="first" style={{color:'#FEFBF0'}}><div>Easy</div></div>
              <div class="second" style={{color:'#FEFBF0'}}><div>Safe And Sound</div></div>
              <div class="third" style={{color:'#FEFBF0'}}><div>Affordable</div></div>
            </section>
          </main>
           </h1>
          <h5 style={{color:'#ffff', fontFamily:'Lato, sans-serif'}}>Explore Our Exclusive Relocation Offers</h5>
          </Fade>
          <div className='mt-4'>
            <a href="tel:+1-833-372-9071" style={{textDecoration:'none'}} ><Button className='button-21'><MdCall size={22} className='me-2 heart text-light'/>Contact Our Team</Button></a>
          </div>
        </div>
        <Fade bottom>
        <div className="display-Form" style={{border:'1px solid #000', borderRadius:'10px', marginTop:'50px',backgroundColor:'#393939', width:'100%',marginLeft:'50px'}}>
          <div className='container my-4'>
            <Row>
              <Col xs={5}>
                <h5 style={{color:'#ffff',fontFamily:'Lato, sans-serif'}}>I am moving from*</h5>
                <Select 
                    isClearable
                    value={predictionsInput}
                    options={predictions}
                    onInputChange={handleInputChange}
                    onChange={handleSelectChange}
                    noOptionsMessage={() => 'Search Your City'}
                    isRequired placeholder='City' />
              </Col>
              <Col xs={5}>
                <h5 style={{color:'#ffff',fontFamily:'Lato, sans-serif'}}>I am moving to*</h5>
                <Select 
                 isClearable
                 value={predictionsInputto}
                 options={predictionsto}
                 onInputChange={handleInputChangeto}
                 onChange={handleSelectChangeto}
                 noOptionsMessage={() => 'Search Your City'}
                 isRequired placeholder='City' />
              </Col>
              <Col xs={2} style={{marginTop:'20px'}}>
                 <Button className='button-70' onClick={handleNextButtonClick} style={{backgroundColor:'#202528', borderColor:'#202528'}}>NEXT</Button>
              </Col>
            </Row>
          </div>
        </div></Fade>
        </Col>
        <Col xs={5}>
        <Fade right>
          <img className='TrackImge' src={TrackImage} alt='BannerImage' style={{position: 'absolute',right: '0'}} /></Fade>
        </Col>
      </Row>
    </div>
  </div> 
  <Fade bottom>
        <div className='container mb-3'>
            <div className="Mobile-Form" style={{border:'1px solid #000', borderRadius:'10px', marginTop:'30px',backgroundColor:'#393939', width:'100%'}}>
              <div className='container my-4'>
                <Row>
                  <Col md={5}>
                    <h5 style={{color:'#ffff',fontFamily:'Lato, sans-serif'}}>I am moving from*</h5>
                    <Select 
                       isClearable
                       value={predictionsInput}
                       options={predictions}
                       onInputChange={handleInputChange}
                       onChange={handleSelectChange}
                       noOptionsMessage={() => 'Search Your City'}
                       isRequired placeholder='From City' />
                  </Col>
                  <Col md={5} className='mt-3'>
                    <h5 style={{color:'#ffff',fontFamily:'Lato, sans-serif'}}>I am moving to*</h5>
                    <Select   
                    isClearable
                     value={predictionsInputto}
                     options={predictionsto}
                     onInputChange={handleInputChangeto}
                     onChange={handleSelectChangeto}
                     noOptionsMessage={() => 'Search Your City'}
                     isRequired placeholder='To City' />
                  </Col>
                  <Col md={2} className="d-flex justify-content-center" style={{ marginTop: '25px' }}>
                    <Button className='button-71' onClick={handleNextButtonClick} style={{ backgroundColor: '#202528', borderColor: '#202528',fontWeight: '600' }}>GET QUOTE</Button>
                  </Col>
                </Row>
              </div>
            </div>
            </div>
      </Fade>
    {/* <div>
      <div style={{position: 'relative', height:'340px'}}>
      <div style={{position: 'absolute',top: 0,left: 0,width: '100%',height: '100%',zIndex: 1 }}>
      <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: '#00000080',}} />
      <div className='bgServiceImage' style={{backgroundImage: `url(${ContactBannerImg})`,backgroundRepeat: 'no-repeat',height: '340px',backgroundSize: 'cover',backgroundPosition: 'center',backgroundPositionY: '-137px',}}>
      <Zoom right>
      <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
          <h1 className='serviceTitle' style={{ color: '#ffffff', marginLeft:'30px', fontFamily:'Lato, sans-serif',fontSize: 'xxx-large',fontWeight: '600' }}>LONG DISTANCE MOVERS</h1>
          <div className='serviceSubTitle' style={{marginLeft:'37px', display:'flex', marginBottom:'50px' }}>
          <Link to='/' style={{textDecoration:"none"}}><h6 style={{color:'#FC4243', marginRight:'10px', cursor:'pointer'}}>Home</h6></Link>
          <h6 style={{color: '#ffffff'}}>/ Long Distance Movers</h6>
          </div>
      </div></Zoom>
      </div>
    </div>
      </div>
    </div> */}
     {/* Circle-Heading */}
    <div className='ms-4 mt-5 pb-4'>
      <Fade right>
      <hr className='hrService'/>
      <h2 style={{fontFamily: 'Lato, sans-serif'}}>Long-distance Moving Services in the UK</h2>
      <h5 style={{lineHeight: '1.6',marginRight: '7px',fontFamily:'Lato, sans-serif',fontWeight: '400'}}>Long-distance moves require careful planning and execution. From your first consultation to the final delivery, our team ensures a professional, stress-free process with clear communication at every step. With All UK Movers, you can trust that your move will be handled with the utmost professionalism, no matter the distance.</h5>
      </Fade>
    </div>
    {/* Circle */}
    <div className='container my-5'>
    <Fade left>
      <Row className=''>
        <Col md={3} xs={6} style={{textAlign:'-webkit-center'}}>
         <div style={{borderRadius:'50%', border:'3px solid #0B259E',width:'120px',height:'120px', justifyContent:'center', display:'flex',alignItems:'center',}}>
          <AiFillSafetyCertificate size={90}/>
         </div>
         <h5 className='CircleText' style={{ marginTop: '20px',fontWeight: 'bold',fontFamily: 'Lato, sans-serif'}}>Safe and Secured</h5>
        </Col>
        <Col md={3} xs={6} style={{textAlign:'-webkit-center'}}>
         <div style={{borderRadius:'50%', border:'3px solid #0B259E',width:'120px',height:'120px', justifyContent:'center', display:'flex',alignItems:'center'}}>
          <IoWalletSharp size={80}/>
         </div>
         <h5 className='CircleText' style={{ marginTop: '20px',fontWeight: 'bold',fontFamily: 'Lato, sans-serif'}}>Affordable</h5>
        </Col>
        <Col md={3} xs={6} style={{textAlign:'-webkit-center'}}>
         <div style={{borderRadius:'50%', border:'3px solid #0B259E',width:'120px',height:'120px', justifyContent:'center', display:'flex',alignItems:'center'}}>
          <AdminPanelSettingsIcon style={{fontSize: '85px'}}/>
         </div>
         <h5 className='CircleText' style={{ marginTop: '20px',fontWeight: 'bold',fontFamily: 'Lato, sans-serif'}}>Private Move</h5>
        </Col>
        <Col md={3} xs={6} style={{textAlign:'-webkit-center'}}>
         <div style={{borderRadius:'50%', border:'3px solid #0B259E',width:'120px',height:'120px', justifyContent:'center', display:'flex',alignItems:'center'}}>
          <FaTruckFast size={80}/>
         </div>
         <h5 className='CircleText' style={{ marginTop: '20px',fontWeight: 'bold',fontFamily: 'Lato, sans-serif'}}>Quick Delivery</h5>
        </Col>
      </Row></Fade>
    </div>
    {/* Detailed List of Services Included */}
    <div className='pt-4'>
        <Fade left>
          <div className='container'>
          <hr className='hrService '/>
          <h2 style={{fontFamily: 'Lato, sans-serif', marginRight: '1px'}}>Detailed List of Services Included</h2>
          </div></Fade>
          <div className='px-3 pt-4'>
          <Fade right>
            <Row className='pb-5'>
              <Col xs={12} md={4} style={{textAlign: '-webkit-center'}}>
              <Card className='zoom zoom1' style={{ width: '18rem',cursor:'pointer',transition: 'transform 0.3s' }}>
                  <Card.Img variant="top" src={serivePlan} style={{height:'191px'}} />
                  <Card.Body>
                    <Card.Title style={{ fontFamily: 'Archivo, sans-serif',fontWeight: '600'}}>Customized Interstate Relocation Plans</Card.Title>
                    <Card.Text style={{fontFamily:'Lato, sans-serif'}}>
                    We provide personalized relocation solutions that include comprehensive packing services, nationwide transportation, secure storage options, specialized equipment, a trained crew, and tailored vehicles.</Card.Text></Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={4} style={{textAlign: '-webkit-center'}}>
              <Card className='zoom zoom1' style={{ width: '18rem',cursor:'pointer',transition: 'transform 0.3s' }}>
                  <Card.Img variant="top" src={serivePlan2}  style={{height:'191px'}}/>
                  <Card.Body>
                  <Card.Title style={{ fontFamily: 'Archivo, sans-serif',fontWeight: '600'}}>Reliable Packing Supplies for Worry-Free Moving</Card.Title>
                    <Card.Text style={{fontFamily:'Lato, sans-serif'}}>
                    Get affordable, comprehensive packing kits with sturdy boxes, cushioning, tape, and specialty wrapping paper to keep your belongings secure. Available 24/7 for a smooth, stress-free long-distance move across the country.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={4} style={{textAlign: '-webkit-center'}}>
              <Card className='zoom zoom1' style={{ width: '18rem',cursor:'pointer',transition: 'transform 0.3s' }}>
                  <Card.Img variant="top" src={serivePlan3}  style={{height:'191px',}} />
                  <Card.Body>
                  <Card.Title style={{ fontFamily: 'Archivo, sans-serif',fontWeight: '600'}}>Furniture Disassembly & Reassembly Services</Card.Title>
                    <Card.Text style={{fontFamily:'Lato, sans-serif'}}>
                    Our long-distance movers expertly disassemble and reassemble desks, beds, cabinets, and even pool tables, ensuring they navigate tight spaces during transit without damage. This meticulous care provides a smooth setup in your new home, ensuring a seamless transition. </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row> 
            <Row className='pb-5'>
              <Col xs={12} md={4} style={{textAlign: '-webkit-center'}}>
              <Card className='zoom zoom1' style={{ width: '18rem',cursor:'pointer',transition: 'transform 0.3s' }}>
                  <Card.Img variant="top" src={serivePlan4} style={{height:'191px'}} />
                  <Card.Body>
                    <Card.Title style={{ fontFamily: 'sans-serif',fontWeight: '600'}}>Trusted Loading & Unloading Support</Card.Title>
                    <Card.Text style={{fontFamily:'Lato, sans-serif'}}>
                    Our experienced cross-country team ensures optimal truck packing, secure item placement, and careful unloading at your destination. We protect your belongings, floors, and doorways while prioritizing safety and efficiency throughout the move. </Card.Text>         
                     </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={4} style={{textAlign: '-webkit-center'}}>
              <Card className='zoom zoom1' style={{ width: '18rem',cursor:'pointer',transition: 'transform 0.3s' }}>
                  <Card.Img variant="top" src={serivePlan6}  style={{height:'191px'}} />
                  <Card.Body>
                  <Card.Title style={{ fontFamily: 'sans-serif',fontWeight: '600'}}>Protect Your Belongings from Coast to Coast</Card.Title>
                    <Card.Text style={{fontFamily:'Lato, sans-serif'}}>
                    Our premium long-distance moving packages feature water-resistant containers, vibration dampeners, and climate-controlled padding, specially designed to safeguard fragile items from motion and environmental risks.
                     </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={4} style={{textAlign: '-webkit-center'}}>
              <Card className='zoom zoom1' style={{ width: '18rem',cursor:'pointer',transition: 'transform 0.3s' }}>
                  <Card.Img variant="top" src={serivePlan8}  style={{height:'191px'}} />
                  <Card.Body>
                  <Card.Title style={{ fontFamily: 'sans-serif',fontWeight: '600'}}>Nationwide Transportation with Guaranteed Timeliness</Card.Title>
                    <Card.Text style={{fontFamily:'Lato, sans-serif'}}>Enjoy peace of mind with reliable, on-time long-distance moving schedules across the UK, including contractually set transport windows and real-time in-transit updates from our fleet. 
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row></Fade>
          </div>
        </div>
    {/* Frequently Asked Questions */}
    <div className='container-fluid'>
      <div className='ms-4 my-4'>
       <hr className='hrService'/>
       <h2 style={{fontFamily: 'Lato, sans-serif'}}>Frequently Asked Questions</h2>
      </div>
    <Row className='pt-5 FQASerivice'>
      <Col xs={12} md={6} className='serviceFaq'>
      <Fade left>
       <img style={{width:'600px'}} src={welcometruck} alt='welcometruck'/></Fade>
      </Col>
      <Col xs={12} md={6} className=''>
      <Fade right>
        <Accordion sty defaultActiveKey={['0']} alwaysOpen flush>
            <Accordion.Item eventKey="0">
                <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>Which areas do you cover for long-distance moving services?
                </h6></Accordion.Header>
                <Accordion.Body style={{fontFamily: 'Lato, sans-serif'}}>
                Our long-distance moving services cater to relocation needs across the UK, with our experienced movers ensuring seamless moves to destinations across London, Manchester, Birmingham, Edinburgh, Glasgow, and all other regions.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>How are your long-distance moving rates and fees structured?</h6></Accordion.Header>
                <Accordion.Body style={{fontFamily: 'Lato, sans-serif'}}>
                For long-distance moves, we provide all-inclusive flat-rate pricing, determined by the total weight of your belongings, the distance traveled, and any extra services needed. Our pricing is transparent, with no hidden fees, and all costs are calculated upfront.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>Do you offer complete packing and unpacking services for long-distance moves?</h6></Accordion.Header>
                <Accordion.Body style={{fontFamily: 'Lato, sans-serif'}}>
                Yes, we offer comprehensive packing and unpacking services for long-distance moves. Our skilled movers will expertly pack your belongings with top-quality materials and, upon arrival, carefully unpack and arrange them in their designated rooms at your new home.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>What are the sizes of the moving trucks used for long-distance relocations?</h6></Accordion.Header>
                <Accordion.Body style={{fontFamily: 'Lato, sans-serif'}}>
                For long-distance moves, we provide specialized moving trucks ranging from 24-foot to 53-foot trailers, depending on the size of your household. These vehicles are equipped with air-ride suspension and climate control to guarantee the safe and secure transportation of your belongings.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
                <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>What measures do you take to ensure the safe transportation of belongings for long-distance moves?</h6></Accordion.Header>
                <Accordion.Body style={{fontFamily: 'Lato, sans-serif'}}>
                We take extensive precautions to ensure the safe transportation of your belongings during long-distance moves. This includes using durable moving blankets, shrink-wrapping furniture, and securely loading items to prevent any shifting. Additionally, our trucks are equipped with GPS tracking for real-time visibility throughout the journey.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
                <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>Do you provide storage options for long-distance moves?</h6></Accordion.Header>
                <Accordion.Body style={{fontFamily: 'Lato, sans-serif'}}>
                Certainly, we offer flexible short-term and long-term storage solutions to meet your needs during a long-distance move. Our secure, climate-controlled storage facilities are ideal for temporarily storing your belongings before, during, or after your relocation.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
                <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>What type of insurance coverage do you offer for long-distance moves?</h6></Accordion.Header>
                <Accordion.Body style={{fontFamily: 'Lato, sans-serif'}}>
                We offer comprehensive valuation coverage to safeguard your belongings during a long-distance move. Our basic protection plan is included in your moving cost, with the option to purchase additional coverage for extra peace of mind.
                </Accordion.Body>
            </Accordion.Item>
            </Accordion></Fade>
        </Col>
    </Row>
   </div>
   {/* TESTIMONIALS */}
  <div className='container'>
   <div className='pt-5 testimonialsNumberSer2'>
    <hr className='hrService'/>
     <p style={{fontFamily: 'Lato, sans-serif', color:'#0924A4'}}>Clients Tales</p>
    </div>
    <div>
         <Row>
          <Col xs={12} md={6} className='testimonialsNumberSer'>
          <Zoom right>
            <h2 style={{fontFamily: 'fantasy',textAlign: '-webkit-match-parent'}}>2000 +</h2>
            <h5 style={{fontFamily:'sans-serif'}}>Satisfied Customers</h5>
            <span>
            We've helped thousands relocate across Canada with ease through our commitment to exceptional service. </span></Zoom>
          </Col>
          <Col xs={12} md={6}>
          <div className='pb-5 testimonialsCard'>
            <Carousel>
              <Carousel.Item interval={2000}>
              <Card style={{ width: '33rem', backgroundColor:'#EFEFEF' }}>
              <Card.Body>
                    <Card.Subtitle className="my-2">
                      <Row>
                        <Col xs={3} md={2}  style={{ textAlign: '-webkit-center'}}>
                          <Avatar src='https://img.freepik.com/free-photo/front-view-smiley-man-holding-book_23-2149915900.jpg?t=st=1708755547~exp=1708759147~hmac=9a1e15c33e46f9540f8a86594279e9eb2bca62d07c3277e465f682e0667f4d82&w=360' alt="Remy Sharp"   />
                        </Col>
                        <Col xs={7} md={8} style={{padding:'0px'}}>
                          <h5 className='mb-0' style={{fontFamily:'Lato, sans-serif'}}>Balaji</h5>
                          <p style={{marginBottom:'0px',fontWeight: 'lighter'}}>in Toronto</p>
                        </Col>
                        <Col xs={2} md={2} className='quoteMobile'>
                        <FormatQuoteIcon style={{fontSize:'50px', color:'#0072BC'}}/>
                        </Col>
                      </Row>
                    </Card.Subtitle>
                    <Card.Text className='text-center mt-3 mb-3' style={{fontFamily:'Lato, sans-serif'}}>
                    The moving crew arrived at my house on time and loaded my belongings with care and attention to detail and finished in the estimated time as per the quote.
                    Because of a delay in moving into my apartment, my stuff had to be stored in the truck for 3 days.
                    </Card.Text>
                  </Card.Body>
              </Card>
              </Carousel.Item>
              <Carousel.Item interval={2000}>
              <Card style={{ width: '33rem',backgroundColor:'#EFEFEF' }}>
              <Card.Body>
                    <Card.Subtitle className="my-2">
                      <Row>
                        <Col xs={3} md={2}  style={{ textAlign: '-webkit-center'}}>
                          <Avatar src="https://cdn.homestars.com/uploaded_images/0367/7899/unnamed_thumb.png" alt="Remy Sharp"  />
                        </Col>
                        <Col xs={7} md={8} style={{padding:'0px'}}>
                          <h5 className='mb-0' style={{fontFamily:'Lato, sans-serif'}}>Elana in Ottawa</h5>
                          <p style={{marginBottom:'0px',fontWeight: 'lighter'}}>Toronto, ON</p>
                        </Col>
                        <Col xs={2} md={2} className='quoteMobile'>
                        <FormatQuoteIcon style={{fontSize:'50px', color:'#0072BC'}}/>
                        </Col>
                      </Row>
                    </Card.Subtitle>
                    <Card.Text className='text-center mt-3 mb-3' style={{fontFamily:'Lato, sans-serif'}}>
                    Abbas and his team are the best movers ever. I have used them about 5 different times over the past few years for downtown and East Toronto short haul moves
                    They are careful, considerate of property, always on time, good listeners and good communicators. Highly recommend!
                    </Card.Text>
                  </Card.Body>
              </Card>
              </Carousel.Item>
              <Carousel.Item interval={2000}>
              <Card style={{ width: '33rem',backgroundColor:'#EFEFEF' }}>
              <Card.Body>
                    <Card.Subtitle className="my-2">
                      <Row>
                        <Col xs={3} md={2}  style={{ textAlign: '-webkit-center'}}>
                          <Avatar src="https://img.freepik.com/free-photo/portrait-smiling-man-outdoors-with-beanie_23-2149256267.jpg?t=st=1708755616~exp=1708759216~hmac=6d2f76bb80e50de8e4cfb8a80ef56eb1450cbe4c63527ea956dbd8d6cc89a429&w=360" alt="Remy Sharp"  />
                        </Col>
                        <Col xs={7} md={8} style={{padding:'0px'}}>
                          <h5 className='mb-0' style={{fontFamily:'Lato, sans-serif'}}>Lawn Maintenance</h5>
                          <p style={{marginBottom:'0px',fontWeight: 'lighter'}}>On Toronto</p>
                        </Col>
                        <Col xs={2} md={2} className='quoteMobile'>
                        <FormatQuoteIcon style={{fontSize:'50px', color:'#0072BC'}}/>
                        </Col>
                      </Row>
                    </Card.Subtitle>
                    <Card.Text className='text-center mt-3 mb-3' style={{fontFamily:'Lato, sans-serif'}}>
                     Called them in the last-minute. They showed as they promised on time. Did an excellent and professional packing and delivered them to my new home with a competitive charge
                    </Card.Text>
                  </Card.Body>
              </Card>
              </Carousel.Item>
            </Carousel>
            </div>
          </Col>
         </Row>
        </div>
  </div>
  
  <div  className='pb-5'>
    <MoreService/>
  </div>
  {/* Footer */}
  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
  <a href="/" class="btnGetQuote" style={{padding: '23px' ,position: 'fixed', zIndex: '9999', right: '20px', bottom: '20px', width: '100px', height: '100px'}}>
    {/* <BsFillChatSquareQuoteFill size={40}/> */}
    <h5 style={{fontFamily: 'Lato,sans-serif', fontWeight:'600'}}>Get <br/>Quote</h5>
  </a>
</div>
  <div className='mt-1'>
    <Footer/>
  </div>
  
</div>
  )
}

export default LongDistanceMovers
