import React,{useEffect} from 'react'
import Header from '../components/Header'
import {Link} from 'react-router-dom';
import ContactBannerImg from '../images/packing2.webp'
import serivePlan from '../images/FurniturePacking.jpg'
import serivePlan2 from '../images/FragileItems.jpg'
import serivePlan3 from '../images/Electronics.jpg'
import serivePlan4 from '../images/ArtworkMirrors.jpg'
import serivePlan8 from '../images/CustomCrating.jpg'
import serivePlan6 from '../images/serivePlan6.jpg'
import welcometruck from '../images/welcome-truck.png'
import {Fade,Zoom} from 'react-reveal';
import {Row,Col,Card,Button} from 'react-bootstrap';
import { AiFillSafetyCertificate } from "react-icons/ai";
import { IoWalletSharp } from "react-icons/io5";
import { FaTruckFast } from "react-icons/fa6";
import { FaBusinessTime } from "react-icons/fa";
import Accordion from 'react-bootstrap/Accordion';
import Carousel from 'react-bootstrap/Carousel';
import Trip from '../images/Trip.jpg'
import Avatar from '@mui/material/Avatar';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import Footer from '../components/FooterMain'
import MoreService from '../components/MoreService'
import { FaBoxesPacking } from "react-icons/fa6";
import { GiBoxUnpacking } from "react-icons/gi";
import RecyclingIcon from '@mui/icons-material/Recycling';
import {Helmet} from 'react-helmet'

const PackagingServices = () => {

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const utmCampaign = searchParams.get('utm_campaign');
    const utmId = searchParams.get('utm_id');

    if (utmCampaign) {
      sessionStorage.setItem('utm_campaign', utmCampaign);
    }

    if (utmId) {
      sessionStorage.setItem('utm_id', utmId);
    }
  }, []);

  useEffect(() => {
    // Function to get the IP address
    const fetchIpAddress = async () => {
      try {
        // Fetch the IP address from IPify API
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        const ipAddress = data.ip;

        // Send the IP address to your API
        await sendIpAddressToApi(ipAddress);

      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    // Function to send the IP address to your API
    const sendIpAddressToApi = async (ipAddress) => {
      try {
        const response = await fetch('https://allcanadavanlines.com/acvlbackend/api/ipaddress', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ipaddress: ipAddress }),
        });

        if (response.ok) {
          console.log('IP address sent successfully');
        } else {
          console.error('Failed to send IP address');
        }
      } catch (error) {
        console.error('Error sending IP address:', error);
      }
    };

    // Call the function to fetch IP and send it to your API
    fetchIpAddress();

  }, []); // Empty dependency array ensures this runs only once when the component mounts

  return (
    <div>
                  <Helmet>
        <title>ACVL in Canada | Professional Packing Services | Expert Packaging </title>
        <meta name="description" content="Ensure safe transportation of your belongings with our professional packing services. Trust our experts to pack securely for worry-free shipping and delivery." />
      </Helmet>
        <Header/>
        {/* Bannar */}
        <div>
          <div style={{position: 'relative', height:'340px'}}>
          <div style={{position: 'absolute',top: 0,left: 0,width: '100%',height: '100%',zIndex: 1 }}>
          <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: '#00000080',}} />
          <div className='bgServiceImage' style={{backgroundImage: `url(${ContactBannerImg})`,backgroundRepeat: 'no-repeat',height: '340px',backgroundSize: 'cover',backgroundPosition: 'center',backgroundPositionY: '-137px',}}>
          <Zoom right>
          <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
              <h1 className='serviceTitle' style={{ color: '#ffffff', marginLeft:'30px', fontFamily:'Lato, sans-serif',fontSize: 'xxx-large',fontWeight: '600' }}>PACKING SERVICES</h1>
              <div className='serviceSubTitle' style={{marginLeft:'37px', display:'flex', marginBottom:'50px' }}>
              <Link to='/' style={{textDecoration:"none"}}><h6 style={{color:'#FC4243', marginRight:'10px', cursor:'pointer'}}>Home</h6></Link>
              <h6 style={{color: '#ffffff',fontFamily:'Lato, sans-serif'}}>/ Packing services</h6>
              </div>
          </div></Zoom>
          </div>
        </div>
          </div>
        </div>
         {/* Circle-Heading */}
        <div className='ms-4 mt-5 pb-4'>
          <Fade right>
          <hr className='hrService'/>
          <h2 style={{fontFamily: 'Lato, sans-serif'}}>Professional Packing Solutions in the UK</h2>
          <h5 style={{lineHeight: '1.6',marginRight: '7px',fontFamily:'Lato, sans-serif',fontWeight: '400'}}> Relocating can be overwhelming, but with All UK Movers’ professional packing services, you can enjoy a stress-free, efficient packing experience. Our team provides full-service packing solutions to ensure your belongings are packed safely and securely, using top-quality materials to protect your valuables during transit.</h5>
          </Fade>
        </div>
        {/* Circle */}
        <div className='container my-5'>
        <Fade left>
          <Row className=''>
            <Col md={3} xs={6} style={{textAlign:'-webkit-center'}}>
             <div style={{borderRadius:'50%', border:'3px solid #0A249D',width:'120px',height:'120px', justifyContent:'center', display:'flex',alignItems:'center',}}>
              <FaBoxesPacking size={80}/>
             </div>
             <h5 className='CircleText' style={{ marginTop: '20px',fontWeight: 'bold',fontFamily: 'Lato, sans-serif'}}>Professional Packing</h5>
            </Col>
            <Col md={3} xs={6} style={{textAlign:'-webkit-center'}}>
             <div style={{borderRadius:'50%', border:'3px solid #0A249D',width:'120px',height:'120px', justifyContent:'center', display:'flex',alignItems:'center'}}>
              <GiBoxUnpacking size={80}/>
             </div>
             <h5 className='CircleText' style={{ marginTop: '20px',fontWeight: 'bold',fontFamily: 'Lato, sans-serif'}}>Premium Materials</h5>
            </Col>
            <Col md={3} xs={6} style={{textAlign:'-webkit-center'}}>
             <div style={{borderRadius:'50%', border:'3px solid #0A249D',width:'120px',height:'120px', justifyContent:'center', display:'flex',alignItems:'center'}}>
              <FaBusinessTime size={80}/>
             </div>
             <h5 className='CircleText' style={{ marginTop: '20px',fontWeight: 'bold',fontFamily: 'Lato, sans-serif'}}>Streamlined and Systematic</h5>
            </Col>
            <Col md={3} xs={6} style={{textAlign:'-webkit-center'}}>
             <div style={{borderRadius:'50%', border:'3px solid #0A249D',width:'120px',height:'120px', justifyContent:'center', display:'flex',alignItems:'center'}}>
              <RecyclingIcon style={{fontSize:'85px', marginBottom:'10px'}}/>
             </div>
             <h5 className='CircleText' style={{ marginTop: '20px',fontWeight: 'bold',fontFamily: 'Lato, sans-serif'}}>Sustainable</h5>
            </Col>
          </Row></Fade>
        </div>
        {/* Detailed List of Services Included */}
        <div className='pt-4'>
        <Fade left>
          <div className='container'>
          <hr className='hrService '/>
          <h2 style={{fontFamily: 'Lato, sans-serif', marginRight: '1px'}}>Detailed List of Services Included</h2>
          </div></Fade>
          <div className='px-3 pt-4'>
          <Fade right>
            <Row className='pb-5'>
              <Col xs={12} md={4} style={{textAlign: '-webkit-center'}}>
              <Card className='zoom zoom1' style={{ width: '18rem',cursor:'pointer',transition: 'transform 0.3s' }}>
                  <Card.Img variant="top" src={serivePlan} style={{ height:'191px'}} />
                  <Card.Body>
                    <Card.Title style={{ fontFamily: 'Archivo, sans-serif',fontWeight: '600'}}>Furniture Packing</Card.Title>
                    <Card.Text style={{fontFamily:'Lato, sans-serif'}}>
                    Our movers carefully wrap and pack furniture using blankets and bubble wrap to ensure protection during transit. We also disassemble and reassemble items as required for safe and efficient transportation.
                    </Card.Text> </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={4} style={{textAlign: '-webkit-center'}}>
              <Card className='zoom zoom1' style={{height: '100%', width: '18rem',cursor:'pointer',transition: 'transform 0.3s' }}>
                  <Card.Img variant="top" src={serivePlan2}  style={{ height:'191px'}}/>
                  <Card.Body>
                  <Card.Title style={{ fontFamily: 'Archivo, sans-serif',fontWeight: '600'}}>Fragile Items Packing</Card.Title>
                    <Card.Text style={{fontFamily:'Lato, sans-serif'}}>
                    Dishes, lamps, and decor are securely packed in durable boxes with plenty of cushioning to protect them from shocks and impacts.</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={4} style={{textAlign: '-webkit-center'}}>
              <Card className='zoom zoom1' style={{height: '100%', width: '18rem',cursor:'pointer',transition: 'transform 0.3s' }}>
                  <Card.Img variant="top" src={serivePlan3}  style={{ height:'191px',}} />
                  <Card.Body>
                  <Card.Title style={{ fontFamily: 'Archivo, sans-serif',fontWeight: '600'}}>Electronics Packing</Card.Title>
                    <Card.Text style={{fontFamily:'Lato, sans-serif'}}>
                    TVs, computers, and other electronics are carefully unplugged, wrapped, and packed to avoid damage. Cables are neatly organized and secured with tape.</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row> 
            <Row className='pb-5'>
              <Col xs={12} md={4} style={{textAlign: '-webkit-center'}}>
              <Card className='zoom zoom1' style={{height: '100%', width: '18rem',cursor:'pointer',transition: 'transform 0.3s' }}>
                  <Card.Img variant="top" src={serivePlan4} style={{ height:'191px'}} />
                  <Card.Body>
                    <Card.Title style={{ fontFamily: 'sans-serif',fontWeight: '600'}}>Artwork and Mirror Packing </Card.Title>
                    <Card.Text style={{fontFamily:'Lato, sans-serif'}}>
                    Frames and mirrors are packed upright in wardrobe boxes with protective padding. Valuable items are insured to prevent cracks and chips</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={4} style={{textAlign: '-webkit-center'}}>
              <Card className='zoom zoom1' style={{height: '100%', width: '18rem',cursor:'pointer',transition: 'transform 0.3s' }}>
                  <Card.Img variant="top" src={serivePlan6}  style={{ height:'191px'}} />
                  <Card.Body>
                  <Card.Title style={{ fontFamily: 'sans-serif',fontWeight: '600'}}>Bulky Item Loading</Card.Title>
                    <Card.Text style={{fontFamily:'Lato, sans-serif'}}>
                    Ramps are used to safely load large items such as treadmills, furniture, and appliances, while cardboard corner protectors safeguard edges.
                     </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={4} style={{textAlign: '-webkit-center'}}>
              <Card className='zoom zoom1' style={{ width: '18rem',cursor:'pointer',transition: 'transform 0.3s' }}>
                  <Card.Img variant="top" src={serivePlan8}  style={{ height:'191px'}} />
                  <Card.Body>
                  <Card.Title style={{ fontFamily: 'Archivo, sans-serif',fontWeight: '600'}}>Reliable Packing Services for a Stress-Free Move</Card.Title>
                    <Card.Text style={{fontFamily:'Lato, sans-serif'}}>
                    Our skilled movers pack your valuables with care, using high-quality materials. We design a personalized plan to suit your unique moving requirements, ensuring a hassle-free relocation. </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row></Fade>
          </div>
        </div>
        {/* Frequently Asked Questions */}
        <div className='container-fluid'>
          <div className='ms-4 my-4'>
           <hr className='hrService'/>
           <h2 style={{fontFamily: 'Lato, sans-serif'}}>Frequently Asked Questions</h2>
          </div>
        <Row className='pt-5 FQASerivice'>
          <Col xs={12} md={6} className='serviceFaq'>
          <Fade left>
           <img style={{width:'600px'}} src={welcometruck} alt='welcometruck'/></Fade>
          </Col>
          <Col xs={12} md={6} className=''>
          <Fade right>
            <Accordion sty defaultActiveKey={['0']} alwaysOpen flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>What delivery options and services do you offer?</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                    We offer a range of delivery services to suit your needs, including standard ground shipping, express air delivery, and freight options for larger or heavier packages across the UK.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header><h6 style={{fontFamily: 'lato,sans-serif'}}>What are your delivery timeframes for national packages?</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                    Delivery timeframes for national package shipping vary based on the origin, destination, and chosen service level. We provide next-day air delivery to many major cities, 2-3 day shipping for regional areas, and 4-7 day coast-to-coast ground service.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>What locations do you deliver packages to?</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                    We deliver packages to residential, commercial, and PO box addresses throughout all provinces and territories in the UK, offering complete nationwide delivery coverage.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato,sans-serif'}}>What is the cost of shipping a package?</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                    Shipping costs are determined by the package's size, weight, and delivery distance. We provide competitive and transparent pricing with no hidden charges. Try our online shipping calculator for instant quotes.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>What packing materials and boxes do you provide for shipping packages?</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                    We offer a range of packing supplies, including durable cardboard boxes in various sizes, reinforced envelopes, bubble pouches, cushioning materials, packing tape, and more to ensure your shipments are well-protected. Custom crating is also available for fragile or oversized items.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>How can I request a price quote and arrange a package pickup? </h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                    To obtain a shipping quote and schedule a pickup, enter your package details into our online system or reach out to our customer service team. We’ll provide a pricing estimate, set up a convenient pickup time and location, and dispatch a courier.
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                    <Accordion.Header><h6 style={{fontFamily: 'Lato, sans-serif'}}>Is it possible to track the status of my package delivery online?</h6></Accordion.Header>
                    <Accordion.Body style={{fontFamily:'Lato, sans-serif'}}>
                    Yes, you can conveniently track your package in real time using our online tracking platform. Just enter your tracking number to view its current location and estimated delivery time.
                    </Accordion.Body>
                </Accordion.Item>
                </Accordion></Fade>
            </Col>
        </Row>
       </div>
       {/* TESTIMONIALS */}
       <div className='container'>
       <div className='pt-5 testimonialsNumberSer2'>
        <hr className='hrService'/>
         <p style={{fontFamily: 'Lato, sans-serif', color:'#0924A4'}}>Happy Customers</p>
        </div>
        <div>
         <Row>
          <Col xs={12} md={6} className='testimonialsNumberSer'>
          <Zoom right>
            <h2 style={{fontFamily: 'fantasy',textAlign: '-webkit-match-parent'}}>2000 +</h2>
            <h5 style={{fontFamily:'sans-serif'}}>Satisfied Customers</h5>
            <span>
            We've assisted thousands of people in relocating across the UK, making their moves smooth and stress-free with our dedication to outstanding service.</span></Zoom>
          </Col>
          <Col xs={12} md={6}>
          <div className='pb-5 testimonialsCard'>
                   <Carousel className='carouselClass'>
                <Carousel.Item interval={2000}>
                <Card style={{ width: '100%', backgroundColor:'#EEEE' }}>
                  <Card.Body>
                    <Card.Subtitle className="my-2">
                      <Row>
                        <Col xs={3} md={2}  style={{ textAlign: '-webkit-center'}}>
                          <Avatar src='https://img.freepik.com/free-photo/front-view-smiley-man-holding-book_23-2149915900.jpg?t=st=1708755547~exp=1708759147~hmac=9a1e15c33e46f9540f8a86594279e9eb2bca62d07c3277e465f682e0667f4d82&w=360' alt="Remy Sharp"   />
                        </Col>
                        <Col xs={7} md={8} style={{padding:'0px'}}>
                          <h5 className='mb-0' style={{fontFamily:'Lato, sans-serif'}}>Vikram</h5>
                          <p style={{marginBottom:'0px',fontWeight: 'lighter'}}>in Leeds</p>
                        </Col>
                        <Col xs={2} md={2} className='quoteMobile'>
                        <FormatQuoteIcon style={{fontSize:'50px', color:'#0072BC'}}/>
                        </Col>
                      </Row>
                    </Card.Subtitle>
                    <Card.Text className='text-center mt-3 mb-3' style={{fontFamily:'Lato, sans-serif'}}>
                    The moving crew arrived punctually at my house, handled my belongings with great care, and completed the loading within the estimated time as quoted. Due to a delay in moving into my apartment, my items had to be stored in the truck for three days.
                    </Card.Text>
                  </Card.Body>
                </Card>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                <Card style={{ width: '100%' , backgroundColor:'#EEEE'}}>
                  <Card.Body>
                    <Card.Subtitle className="my-2">
                      <Row>
                        <Col xs={3} md={2}  style={{ textAlign: '-webkit-center'}}>
                          <Avatar src="https://cdn.homestars.com/uploaded_images/0367/7899/unnamed_thumb.png" alt="Remy Sharp"  />
                        </Col>
                        <Col xs={7} md={8} style={{padding:'0px'}}>
                          <h5 className='mb-0' style={{fontFamily:'Lato, sans-serif'}}>Amit</h5>
                          <p style={{marginBottom:'0px',fontWeight: 'lighter'}}>in London</p>
                        </Col>
                        <Col xs={2} md={2} className='quoteMobile'>
                        <FormatQuoteIcon style={{fontSize:'50px', color:'#0072BC'}}/>
                        </Col>
                      </Row>
                    </Card.Subtitle>
                    <Card.Text className='text-center mt-3 mb-3' style={{fontFamily:'Lato, sans-serif'}}>
                    I contacted them at the last minute, and they arrived as promised, right on time. They provided excellent, professional packing services and delivered everything to my new home at a competitive price.
                    </Card.Text>
                  </Card.Body>
                </Card>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                <Card style={{ width: '100%',backgroundColor:'#EEEE' }}>
                  <Card.Body>
                    <Card.Subtitle className="my-2">
                      <Row>
                        <Col xs={3} md={2}  style={{ textAlign: '-webkit-center'}}>
                          <Avatar src="https://img.freepik.com/free-photo/portrait-smiling-man-outdoors-with-beanie_23-2149256267.jpg?t=st=1708755616~exp=1708759216~hmac=6d2f76bb80e50de8e4cfb8a80ef56eb1450cbe4c63527ea956dbd8d6cc89a429&w=360" alt="Remy Sharp"  />
                        </Col>
                        <Col xs={7} md={8} style={{padding:'0px'}}>
                          <h5 className='mb-0' style={{fontFamily:'Lato, sans-serif'}}>Ravi</h5>
                          <p style={{marginBottom:'0px',fontWeight: 'lighter'}}>in Manchester</p>
                        </Col>
                        <Col xs={2} md={2} className='quoteMobile'>
                        <FormatQuoteIcon style={{fontSize:'50px', color:'#0072BC'}}/>
                        </Col>
                      </Row>
                    </Card.Subtitle>
                    <Card.Text className='text-center mt-3 mb-3' style={{fontFamily:'Lato, sans-serif'}}>
                    Arjun and his team are hands down the best movers I've ever worked with. Over the past few years, I've hired them for five different short-haul moves within downtown and Oxford. They are careful with my belongings, considerate of property, always punctual, great listeners, and excellent communicators. I highly recommend them!
                    </Card.Text>
                  </Card.Body>
                </Card>
                </Carousel.Item>
              </Carousel>
            </div>
          </Col>
         </Row>
        </div>
      </div>
  
      
      <div  className='pb-5'>
        <MoreService/>
      </div>
      {/* Footer */}
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
  <a href="/" class="btnGetQuote" style={{padding: '23px' ,position: 'fixed', zIndex: '9999', right: '20px', bottom: '20px', width: '100px', height: '100px'}}>
    {/* <BsFillChatSquareQuoteFill size={40}/> */}
    <h5 style={{fontFamily: 'Lato,sans-serif', fontWeight:'600'}}>Get <br/>Quote</h5>
  </a>
</div>
      <div className='mt-1'>
        <Footer/>
      </div>
      
    </div>
  )
}

export default PackagingServices
