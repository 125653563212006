import React from 'react'
import {Row,Col} from 'react-bootstrap';
import { FaTruckLoading } from "react-icons/fa";
import { FaTruckFast } from "react-icons/fa6";
import {Link} from 'react-router-dom'
import { MdDelete } from "react-icons/md";
import { PiPackageFill } from "react-icons/pi";
import { FaTruckMoving } from "react-icons/fa6";
import { MdOutlineStorage } from "react-icons/md";
import {Fade,Zoom} from 'react-reveal'
import {Button} from 'react-bootstrap'
const MoreService = () => {
  return (
    <div className='container'>
      <div className='text-center py-5'>
      <Fade right> <h2 style={{fontFamily:'Lato, sans-serif'}}>Explore More Service</h2></Fade>
      </div>
      <Zoom left>
      <Row>
        {/* <Col xs={6} md={2}  className='text-center zoomSer py-3' style={{transition: 'transform 0.3s'}}> */}
        <Col xs={6} md={2}  className='text-center zoomSer py-3' style={{transition: 'transform 0.3s',boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'}}>
           <FaTruckLoading size={80}/>
           <h6  style={{fontFamily:'Lato, sans-serif',fontWeight: '700', color:'#FC4243',marginTop:'10px',minHeight: '38px'}}>Local Mover</h6>
           <p style={{fontFamily:'Lato, sans-serif',minHeight: '100px'}}>We ensure hassle-free relocations within your area</p>
           <a href='/local-movers'><button class="button-70" >Explore</button></a>
        </Col>
        <Col xs={6} md={2}  className='text-center zoomSer py-3' style={{transition: 'transform 0.3s',boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'}}>
           <FaTruckFast size={80}/>
           <h6 style={{fontFamily:'sans-serif',fontWeight: '700', color:'#FC4243',marginTop:'10px',minHeight: '38px'}}>Long Distance Movers</h6>
           <p style={{fontFamily:'Lato, sans-serif',minHeight: '100px'}}>We manage smooth and efficient moves across long distances</p>
           <a href='/long-distance-movers'><button class="button-70">Explore</button></a>
        </Col>
        <Col xs={6} md={2}  className='text-center zoomSer py-3' style={{transition: 'transform 0.3s',boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'}}>
           <MdDelete size={80}/>
           <h6 style={{fontFamily:'Lato, sans-serif',fontWeight: '700', color:'#FC4243',marginTop:'10px',minHeight: '38px'}}>Junk Removal</h6>
           <p style={{fontFamily:'Lato, sans-serif',minHeight: '100px'}}>We quickly and responsibly remove your unwanted items.</p>
           <a href='/junk-removal'><button  class="button-70">Explore</button></a>
        </Col>
        <Col xs={6} md={2}  className='text-center zoomSer py-3' style={{transition: 'transform 0.3s',boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'}}>
           <PiPackageFill  size={80}/>
           <h6 style={{fontFamily:'Lato, sans-serif',fontWeight: '700', color:'#FC4243',marginTop:'10px',minHeight: '38px'}}>Packing Services</h6>
           <p style={{fontFamily:'Lato, sans-serif',minHeight: '100px'}}>We expertly pack your items to ensure safe transportation.</p>
           <a href='/packaging-services'><button   class="button-70">Explore</button></a>
        </Col>
        <Col xs={6} md={2}  className='text-center zoomSer py-3' style={{transition: 'transform 0.3s',boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'}}>
           <FaTruckMoving size={80}/>
           <h6 style={{fontFamily:'Lato, sans-serif',fontWeight: '700', color:'#FC4243',marginTop:'10px',minHeight: '38px'}}>Moving Supplies</h6>
           <p style={{fontFamily:'Lato, sans-serif',minHeight: '100px'}}>We provide cost-effective DIY packing and moving materials.</p>
           <a href='/moving-supplier'><button  class="button-70">Explore</button></a>
        </Col>
        <Col xs={6} md={2}  className='text-center zoomSer py-3' style={{transition: 'transform 0.3s',boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px'}}>
           <MdOutlineStorage size={80}/>
           <h6 style={{fontFamily:'Lato, sans-serif',fontWeight: '700', color:'#FC4243',marginTop:'10px',minHeight: '38px'}}>Storage Services</h6>
           <p style={{minHeight: '100px'}}>We offer flexible and accessible storage options to suit your needs.</p>
           <a href='/storage-services'><button  class="button-70">Explore</button></a>
        </Col>
      </Row>

      </Zoom>
    </div>
  )
}

export default MoreService
